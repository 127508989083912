/* Estilos para centrar el spinner y crear un fondo semitransparente */
.spinner-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* Asegúrate de que esté delante de otros elementos */
}

.spinner {
  padding: 20px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  /* Fondo semitransparente */
  z-index: 1300;
  /* Asegúrate de que esté por encima de otros elementos */
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}